<template>
  <div class="flex flex-col lg:flex-row min-h-screen">
    <div class="w-full lg:w-1/2 bg-white flex flex-col">
      <div class="p-4 lg:p-8">
        <NuxtLink
          to="/print"
          class="text-primary flex items-center gap-2"
          @click.prevent="handleBackToPrint"
        >
          <Icon :icon="ArrowLeftSvg" />
          {{ t('print.back-to-print-store') }}
        </NuxtLink>
      </div>

      <div class="flex-1 flex flex-col px-4 lg:px-8">
        <h2 class="text-xl font-semibold mb-4">
          {{ t('print.order-summary') }}
        </h2>

        <div class="space-y-4">
          <div
            v-for="(item, key) in printStore.selectedItems"
            :key="key"
            class="p-6 bg-white rounded-2xl flex gap-4"
          >
            <img
              :src="item.customPreviewUrl || item.previewUrl || item.imageUrl"
              class="w-32 h-32 object-cover rounded"
              alt=""
            />
            <div class="flex-1">
              <div class="flex justify-between items-start mb-2">
                <div>
                  <h3 class="text-2xl font-medium">{{ item.title }}</h3>

                  <div class="space-y-1 mt-2">
                    <p class="text-[gray] font-medium">
                      {{ item.printProduct.name }}
                    </p>
                    <p class="text-[gray]">
                      {{ item.printProduct.mediumName }}
                    </p>
                    <p class="text-[gray]">
                      Dimensions: {{ item.printProduct.dimensions }}
                    </p>
                  </div>

                  <p
                    v-if="printStore.checkout.step >= 2 || paymentSuccessful"
                    class="text-[gray] mt-2"
                  >
                    QTY: {{ printStore.quantities[key] }}
                  </p>
                </div>
              </div>

              <div
                v-if="printStore.checkout.step === 1 && !paymentSuccessful"
                class="flex items-center gap-2 mt-4"
              >
                <div class="flex flex-col relative">
                  <SelectInput
                    v-model="printStore.quantities[key]"
                    :options="getQuantityOptions(item)"
                    placeholder="Select quantity"
                    option-value-name="quantity"
                    option-label-name="displayText"
                    class="w-[200px] print-select"
                    @update:model-value="
                      () => updateQuantity(key, printStore.quantities[key])
                    "
                  >
                    <template #option="{ option }">
                      <div class="flex justify-between w-full">
                        <span class="font-medium">
                          {{
                            option.quantity
                              ? option.displayText
                              : 'Clear Selection'
                          }}
                        </span>
                        <span v-if="option.quantity" class="text-primary">
                          ${{
                            (option.quantity * option.pricePerUnit).toFixed(2)
                          }}
                        </span>
                      </div>
                    </template>
                  </SelectInput>
                </div>

                <button
                  class="ml-auto text-[gray] hover:text-[red] transition-colors"
                  @click="removeItem(key)"
                >
                  <Icon :icon="TrashSvg" class="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8 space-y-4 border-t border-gray-200 pt-4">
          <div class="flex justify-between items-center">
            <span class="text-[#6B7280] text-lg">{{
              t('print.subtotal')
            }}</span>
            <span class="text-lg">${{ printStore.subtotal.toFixed(2) }}</span>
          </div>

          <template v-if="printStore.checkout.step >= 2">
            <div class="h-px bg-[#E5E7EB]"></div>
            <div class="flex justify-between items-center">
              <span class="text-[#6B7280] text-lg">{{
                t('print.shipping')
              }}</span>
              <span class="text-lg"
                >${{
                  (printStore.checkout.shippingRate?.amount || 0).toFixed(2)
                }}</span
              >
            </div>
          </template>

          <template v-if="printStore.checkout.step === 3">
            <div class="h-px bg-[#E5E7EB]"></div>
            <div class="flex justify-between items-center">
              <span class="text-[#6B7280] text-lg">
                {{ t('print.sales-tax') }}
                <span v-if="isCalculating" class="text-sm text-[gray] ml-2">
                  (calculating...)
                </span>
              </span>
              <span class="text-lg"
                >${{ printStore.checkout.taxAmount.toFixed(2) }}</span
              >
            </div>
          </template>

          <div class="h-px bg-[#E5E7EB]"></div>
          <div class="flex justify-between items-center">
            <span class="text-[#6B7280] text-xl font-medium">{{
              t('print.total')
            }}</span>
            <span class="text-xl font-medium">
              ${{
                (
                  printStore.subtotal +
                  (printStore.checkout.step >= 2
                    ? printStore.checkout.shippingRate?.amount || 0
                    : 0) +
                  (printStore.checkout.step === 3
                    ? printStore.checkout.taxAmount
                    : 0)
                ).toFixed(2)
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full lg:w-1/2 bg-[#F8FAFC] p-4 lg:p-8">
      <div class="flex justify-center mb-8 lg:mb-12">
        <div class="flex items-center gap-2 lg:gap-4 text-sm lg:text-base">
          <span
            :class="[
              printStore.checkout.step === 1 ? 'text-primary' : 'text-[gray]',
            ]"
            class="font-medium"
          >
            {{ t('print.shipping') }}
          </span>
          <Icon
            :icon="ChevronRightSvg"
            class="w-4 h-4 lg:w-5 lg:h-5 text-[gray]"
          />
          <span
            :class="[
              printStore.checkout.step === 2 ? 'text-primary' : 'text-[gray]',
            ]"
            class="font-medium"
          >
            {{ t('print.delivery') }}
          </span>
          <Icon
            :icon="ChevronRightSvg"
            class="w-4 h-4 lg:w-5 lg:h-5 text-[gray]"
          />
          <span
            :class="[
              printStore.checkout.step === 3 ? 'text-primary' : 'text-[gray]',
            ]"
            class="font-medium"
          >
            {{ t('print.payment') }}
          </span>
        </div>
      </div>

      <component
        :is="currentStep"
        @next="handleNextStep"
        @back="handlePreviousStep"
        @payment-success="handlePaymentSuccess"
      />
    </div>
  </div>

  <div class="w-full bg-white p-4 lg:p-8 border-gray">
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Icon } from '~/components/icon';
import ArrowLeftSvg from '~/assets/icons/arrow-left.svg?component';
import { usePrintStore } from '~/store/print';
import ShippingStep from './_steps/shipping-step.vue';
import PaymentStep from './_steps/payment-step.vue';
import TrashSvg from '~/assets/icons/trash.svg?component';
import ChevronRightSvg from '~/assets/icons/chevron-right.svg?component';
import { useStripeTax } from '~/composables/useStripeTax';
import DeliveryStep from './_steps/delivery-step.vue';
import { Footer } from '~/components/footer';
import { SelectInput } from '~/components/inputs/select-input';

const { t } = useI18n();
const printStore = usePrintStore();

const { calculateTax, isCalculating } = useStripeTax();

const currentStep = computed(() => {
  switch (printStore.checkout.step) {
    case 1: {
      return ShippingStep;
    }
    case 2: {
      return DeliveryStep;
    }
    case 3: {
      return PaymentStep;
    }
    default: {
      return ShippingStep;
    }
  }
});

const paymentSuccessful = ref(false);

const handleNextStep = async () => {
  if (printStore.checkout.step === 2) {
    const taxAmount = await calculateTax({
      subtotal: printStore.subtotal,
      shippingAmount: printStore.checkout.shippingRate?.amount || 0,
      address: printStore.checkout.shippingAddress.address,
    });

    printStore.setTaxAmount(taxAmount);
    printStore.setCheckoutStep(3);
  }
};

const handlePreviousStep = () => {
  if (printStore.checkout.step > 1) {
    printStore.setCheckoutStep(printStore.checkout.step - 1);
  }
};

const getItemTiers = (item: PrintableItem) => {
  return [...(item.printProduct.priceTiers || [])].sort(
    (a, b) => a.quantity - b.quantity,
  );
};

const removeItem = (key: string) => {
  printStore.updateQuantity(key, 0);

  if (!printStore.hasItems) {
    navigateTo('/print');
  }
};

const updateQuantity = (key, quantity) => {
  if (quantity === 0) {
    removeItem(key);
  } else {
    printStore.updateQuantity(key, quantity);
  }
};

const handleBackToPrint = () => {
  printStore.resetCheckout();
  navigateTo('/print');
};

const handlePaymentSuccess = () => {
  paymentSuccessful.value = true;
  printStore.resetAll();
};

const getQuantityOptions = (item: PrintableItem) => {
  const tiers = getItemTiers(item);
  return [
    { quantity: 0, displayText: 'Clear Selection', pricePerUnit: 0 },
    ...tiers.map((tier) => ({
      quantity: tier.quantity,
      displayText: tier.quantity.toString(),
      pricePerUnit: tier.pricePerUnit,
    })),
  ];
};
</script>

<style scoped>
.print-select {
  :deep(.input-area) {
    @apply rounded-lg !important;
    @apply bg-[white] !important;
    @apply border-[gray] !important;
    @apply w-full !important;
  }

  :deep(input) {
    @apply bg-[white] !important;
    @apply placeholder-[gray] !important;
    @apply text-[gray] !important;
    @apply w-full !important;
  }

  :deep(.options-area) {
    @apply rounded-lg !important;
    @apply mt-1 !important;
    @apply shadow-lg !important;
    @apply bg-[white] !important;
    @apply border-[gray] !important;
    @apply w-full !important;
  }

  :deep(.options-area-item) {
    @apply hover:bg-[gray] !important;
    @apply rounded-none !important;
    @apply text-[gray] !important;
  }

  :deep(.options-area-item.option-selected) {
    @apply bg-[gray] !important;
  }
}
</style>
