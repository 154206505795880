import { ref } from 'vue';
import { useApi } from '~/api';

type TaxCalculationParams = {
  subtotal: number;
  shippingAmount: number;
  address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
};

export const useStripeTax = () => {
  const taxAmount = ref(0);
  const isCalculating = ref(false);
  const error = ref<string | null>(null);

  const calculateTax = async (
    params: TaxCalculationParams,
  ): Promise<number> => {
    isCalculating.value = true;
    error.value = null;

    try {
      const api = useApi();
      const response = await api('/app/tax/calculate', {
        method: 'POST',
        body: {
          amount: Math.round(params.subtotal * 100),
          shipping_amount: Math.round(params.shippingAmount * 100),
          address: {
            line1: params.address.line1,
            line2: params.address.line2,
            city: params.address.city,
            state: params.address.state,
            postal_code: params.address.postal_code,
            country: params.address.country,
          },
        },
      });

      taxAmount.value = response.tax_amount / 100;
      return taxAmount.value;
    } catch {
      error.value = 'Failed to calculate tax';
      return 0;
    } finally {
      isCalculating.value = false;
    }
  };

  return {
    taxAmount,
    isCalculating,
    error,
    calculateTax,
  };
};
